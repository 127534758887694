import * as React from "react";

function BurgerDots({ fill, width, height }) {
  return (
    <svg width={width || 25} height={height || 25} viewBox="0 0 6.052 6.052">
      <path
        d="M3.018 0a.786.786 0 00-.778.787.786.786 0 00.786.786.786.786 0 00.786-.786A.786.786 0 003.026 0a.786.786 0 00-.008 0zm0 2.24a.786.786 0 00-.778.786.786.786 0 00.786.786.786.786 0 00.786-.786.786.786 0 00-.786-.786.786.786 0 00-.008 0zm0 2.24a.786.786 0 00-.778.785.786.786 0 00.786.787.786.786 0 00.786-.787.786.786 0 00-.786-.786.786.786 0 00-.008 0z"
        fill={fill || "#fff"}
      />
    </svg>
  );
}

const MemoBurgerDots = React.memo(BurgerDots);
export default MemoBurgerDots;

import * as React from "react";

function AdriftCircle({ fill }) {
  return (
    <svg fill={fill} viewBox="0 0 105.83 105.83">
      <path d="M52.916-.003A52.916 52.916 0 000 52.913a52.916 52.916 0 0052.916 52.916 52.916 52.916 0 0052.916-52.916A52.916 52.916 0 0052.916-.003zM80.98 21.42c2.3.054 3.048.517 3.048.517s-15.05 6.133-15.692 8.13c-.641 1.997 1.642 2.854 1.642 2.854s7.632 2.71.713 21.042c0 0-2.747 6.821-12.255 10.33l1.198 5.24c2.71.214 8.346 4.635 8.845 6.276.5 1.64-.855 1.213-.855 1.213l-3.781-3.21-1.57-1.07-1.497.57c2.496 2.64 1.14 3.424 1.14 3.424l-1.068-.856h-4.494l-.855.5c-4.209-2.853-3.353-5.564-3.353-5.564-.377-.646-1.158-3.24-1.6-4.733-2.576.276-5.448.334-8.671.077 0 0-25.155 9.997-29.98 11.269-.23.077-.442.132-.618.143.134-.018.375-.079.618-.143C16.24 75.958 29.68 63.164 29.68 63.164l-13.053 3.708c1.498-1.926 17.546-10.056 17.546-10.056C38.95 43.05 56.926 38.984 56.926 38.984c-3.067-3.138-1.711-10.343-1.711-10.343 3.324-10.636 13.409-5.277 13.409-5.277 6.205-1.64 10.057-1.997 12.357-1.944zM56.277 64.99a32.67 32.67 0 01-3.234.743c.95 2.002 2.482 4.684 3.104 2.625a6.829 6.829 0 00.13-3.369z" />
    </svg>
  );
}

const MemoAdriftCircle = React.memo(AdriftCircle);
export default MemoAdriftCircle;

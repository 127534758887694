import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Container from "../comp-layouts/Container";
import Grid from "../comp-layouts/Grid";
import SiteLogo from "../site/SiteIcon";
import Owl from "../comp-layouts/Owl";
import theme from "../../theme/theme.json";
import T from "../Text";
import MemoInstaIcon from "../icons/InstaIcon";
import MemoFacebookIcon from "../icons/FacebookIcon";
import MemoTwitterIcon from "../icons/TwitterIcon";

const Footer = () => {
  return (
    <StyledFooter>
      <Container width="1024px">
        <FooterGrid>
          <Owl margin="1rem">
            <StyledLink
              aria-label="adrift lab logo"
              to="/"
              style={{ textDecoration: "none" }}
            >
              <SiteLogo />
            </StyledLink>
            <p>
              We're a dedicated group of researchers studying all things adrift
              in the ocean.
            </p>
          </Owl>
          <Owl margin="1rem">
            <T ff={theme.ff.primary} fw={700} fs={theme.fs["xl"]}>
              About
            </T>
            <StyledNav>
              <Link to="/about">About Us</Link>
              <Link to="/core-team">Our Team</Link>
              <Link to="/news">News</Link>
              <Link to="/publications">Research</Link>
            </StyledNav>
          </Owl>
          <Owl margin="1rem">
            <T ff={theme.ff.primary} fw={700} fs={theme.fs["xl"]}>
              Students
            </T>
            <StyledNav>
              <Link to="/current-students">Current Students</Link>
              <Link to="/join-adrift-lab">Future Students</Link>
              <Link to="/alumni">Alumni</Link>
            </StyledNav>
          </Owl>
          <div>
            <T ff={theme.ff.primary} fw={700} fs={theme.fs["xl"]}>
              Contact
            </T>
            <FooterIconContainer>
              <a
                aria-label="facebook icon"
                target="_blank"
                href="https://www.facebook.com/adriftlab"
                rel="noopener"
              >
                <MemoFacebookIcon height={30} width={30} />
              </a>
              <a
                aria-label="Instagram icon"
                target="_blank"
                href="https://www.instagram.com/adriftlabscience/"
                rel="noopener"
                style={{ marginLeft: 5 }}
              >
                <MemoInstaIcon height={30} width={30} />
              </a>
              <a
                aria-label="Twitter icon"
                target="_blank"
                href="https://twitter.com/AdriftLab"
                rel="noopener"
                style={{ marginLeft: 5 }}
              >
                <MemoTwitterIcon height={30} width={30} />
              </a>
            </FooterIconContainer>
            <a
              style={{ display: "block", marginBottom: "1rem" }}
              href="mailto:adriftlab@gmail.com"
            >
              adriftlab@gmail.com
            </a>
            <Link to="/contact">Contact</Link>
            <hr style={{ margin: "30px 0", opacity: 0.5 }} />
            <T t="p" fs={theme.fs.xs}>
              Design and development by{" "}
              <a href="https://jessebenjamin.me" rel="noopener" target="_blank">
                Jesse Benjamin Creative
              </a>
            </T>
          </div>
        </FooterGrid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  padding: 80px 0;
  background-color: ${theme.col.primary.low};
  color: white;
  min-height: 300px;
`;
const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  transition: 0.3s opacity;
  & svg {
    width: 200px;
  }
  & :hover {
    opacity: 0.6;
  }
`;
const StyledNav = styled.nav`
  & a {
    display: block;
  }
`;

const FooterIconContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  max-width: 105px;

  & a {
    display: flex;
  }
  @media (min-width: 900px) {
    margin: 1rem auto;
  }
`;

const FooterGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  & div {
    text-align: left;
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
    & div {
      text-align: center;
    }
    & div:first-of-type {
      text-align: left;
    }
  }
`;

import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import theme from "../../../theme/theme.json";

const CurtainContainer = ({ isVisible, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="child"
          initial={{
            minWidth: "max-content",
            position: "absolute",
            overflow: "hidden",
            zIndex: 10001,
            height: 0,
            top: 53,
            backgroundColor: `${theme.col.primary.low}`,
          }}
          animate={{ height: "max-content" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CurtainContainer;

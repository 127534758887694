import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import MemoSiteLogo from "../../site/SiteIcon";
import Curtain from "../Curtain/Curtain";

const Mobile = () => {
  return (
    <MobileNav>
      <Link aria-label="adrift lab logo" to="/">
        <Aligner>
          <MemoSiteLogo height="4vh" />
        </Aligner>
      </Link>
      <Curtain />
    </MobileNav>
  );
};

export default Mobile;

const Aligner = styled.div`
  display: flex;
  align-items: center;
`;

const MobileNav = styled.nav`
  padding: 0 5vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

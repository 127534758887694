import * as React from "react";

function FacebookIcon({ fill, width, height }) {
  return (
    <svg width={width || 25} height={height || 25} viewBox="0 0 10.971 10.971">
      <path
        d="M5.48 0A5.485 5.485 0 000 5.486a5.485 5.485 0 005.486 5.485 5.485 5.485 0 005.485-5.485A5.485 5.485 0 005.486 0a5.485 5.485 0 00-.006 0zm.667 2.236h.16c.441 0 .662.03.662.092v.946h-.63c-.38.035-.57.214-.57.538v.76c0 .04.022.061.063.061h1.074v.062c-.065.693-.118 1.039-.16 1.039h-.914c-.035 0-.055.022-.062.067v2.935H4.649l-.016-.19V5.831c-.03-.065-.062-.098-.093-.098h-.537v-1.1h.537c.062-.035.093-.068.093-.099l-.03-.977a3.39 3.39 0 01.123-.506c.22-.544.694-.817 1.421-.817z"
        fill={fill || "#fff"}
      />
    </svg>
  );
}

const MemoFacebookIcon = React.memo(FacebookIcon);
export default MemoFacebookIcon;

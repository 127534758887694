import * as React from "react";

function TwitterIcon({ fill, height }) {
  return (
    <svg height={height || 25} viewBox="0 0 10.971 10.971">
      <path
        d="M5.48 0A5.485 5.485 0 000 5.485a5.485 5.485 0 005.486 5.486 5.485 5.485 0 005.485-5.486A5.485 5.485 0 005.486 0a5.485 5.485 0 00-.006 0zm1.171 3.57h.253c.19.022.4.127.63.316h.094c.065 0 .253-.074.563-.222h.03v.03c-.096.2-.242.357-.438.471l.03.067c.218-.045.387-.098.507-.16v.031c-.003.038-.172.217-.506.538v.217c0 .575-.231 1.183-.693 1.824-.64.734-1.416 1.1-2.325 1.1-.434 0-.948-.136-1.54-.408v-.062h.186c.407 0 .816-.126 1.23-.377v-.093c-.369 0-.674-.22-.915-.661v-.062l.16.03h.155l.062-.03c-.523-.29-.785-.636-.785-1.04v-.03a.896.896 0 00.44.124v-.031c-.266-.269-.412-.51-.44-.724v-.377c.083-.134.124-.219.124-.253h.036l.44.408c.471.372 1.036.603 1.694.693.045 0 .068-.021.068-.062v-.254c0-.168.03-.304.093-.408.213-.396.496-.594.847-.594z"
        fill={fill || "#fff"}
      />
    </svg>
  );
}

const MemoTwitterIcon = React.memo(TwitterIcon);
export default MemoTwitterIcon;

import React from "react";
import styled from "styled-components";

const Container = ({ children, width, padding, mt, mb, ml, mr }) => {
  return (
    <ContentContainer
      cWidth={width}
      cPad={padding}
      top={mt}
      bottom={mb}
      right={mr}
      left={ml}
    >
      {children}
    </ContentContainer>
  );
};

export default Container;

const ContentContainer = styled.div`
  padding-top: ${(props) => props.cPad || 0};
  padding-bottom: ${(props) => props.cPad || 0};
  margin: 0 auto;
  margin-top: ${(props) => props.top || 0};
  margin-bottom: ${(props) => props.bottom || 0};
  width: 88vw;

  max-width: ${(props) => props.cWidth || "768px"};
`;

import * as React from "react";

function XIcon({ fill, width, height }) {
  return (
    <svg width={width || 25} height={height || 25} viewBox="0 0 26.458 26.458">
      <path
        d="M2.644 0A2.646 2.646 0 00.775.775a2.646 2.646 0 000 3.742l8.712 8.712L.775 21.94a2.646 2.646 0 000 3.742 2.646 2.646 0 003.74 0l8.714-8.712 8.712 8.712a2.646 2.646 0 003.742 0 2.646 2.646 0 000-3.742l-8.713-8.712 8.713-8.712a2.646 2.646 0 000-3.742A2.646 2.646 0 0023.809 0a2.646 2.646 0 00-1.868.775l-8.713 8.712L4.516.775A2.646 2.646 0 002.644 0z"
        fill={fill || "#fff"}
      />
    </svg>
  );
}

const MemoXIcon = React.memo(XIcon);
export default MemoXIcon;

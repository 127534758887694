import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import MemoSiteLogo from "../../site/SiteIcon";
import TeamDropper from "./TeamDropper";
import theme from "../../../theme/theme.json";
import MemoInstaIcon from "../../icons/InstaIcon";
import MemoFacebookIcon from "../../icons/FacebookIcon";
import MemoTwitterIcon from "../../icons/TwitterIcon";

const Desktop = () => {
  return (
    <DesktopNav>
      <Aligner>
        <Link
          aria-label="adrift lab logo"
          style={{ textDecoration: "none" }}
          to="/"
        >
          <Aligner>
            <MemoSiteLogo height="4.5vh" style={{ minHeight: "40px" }} />
          </Aligner>
        </Link>
      </Aligner>
      <Aligner>
        <DesktopNavList>
          <li>
            <StyledSoloLink to="/about">About</StyledSoloLink>
          </li>
          <li>
            <StyledSoloLink to="/news">News</StyledSoloLink>
          </li>
          <li>
            <TeamDropper label="Team"></TeamDropper>
          </li>
          <li>
            <StyledSoloLink to="/publications">Research</StyledSoloLink>
          </li>
          <li>
            <StyledSoloLink to="/join-adrift-lab">Join</StyledSoloLink>
          </li>
          <li>
            <StyledSoloLink to="/contact">Contact</StyledSoloLink>
          </li>
          <FooterIconContainer>
            <a
              aria-label="facebook icon"
              target="_blank"
              href="https://www.facebook.com/adriftlab"
              rel="noopener"
            >
              <MemoFacebookIcon height={25} width={25} />
            </a>
            <a
              aria-label="Instagram icon"
              target="_blank"
              href="https://www.instagram.com/adriftlabscience/"
              rel="noopener"
              style={{ marginLeft: 5 }}
            >
              <MemoInstaIcon height={25} width={25} />
            </a>
            <a
              aria-label="Twitter icon"
              target="_blank"
              href="https://twitter.com/AdriftLab"
              rel="noopener"
              style={{ marginLeft: 5 }}
            >
              <MemoTwitterIcon height={25} width={25} />
            </a>
          </FooterIconContainer>
        </DesktopNavList>
      </Aligner>
    </DesktopNav>
  );
};

export default Desktop;

const Aligner = styled.div`
  display: flex;
  align-items: center;
`;
const DesktopNav = styled.nav`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 30px;
  }
`;

const DesktopNavList = styled.ul`
  display: flex;
  align-items: center;
`;

const StyledSoloLink = styled((props) => <Link {...props} />)`
  font-family: ${theme.ff.primary};
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.75rem;
  transition: color 0.6s;
  text-decoration: none;
  display: inline-block;
  color: white;
  & :hover {
    color: ${theme.col.primary.light};
    opacity: 1;
    cursor: pointer;
  }
`;

const FooterIconContainer = styled.div`
  display: flex;
  padding-left: 15px;
  justify-content: space-between;
  max-width: 105px;
  & a {
    display: flex;
  }
`;

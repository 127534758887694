import React from "react"
import { motion, AnimatePresence } from "framer-motion"

const CurtainContainer = ({ isVisible, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="child"
          initial={{
            width: 0,
            position: "fixed",
            zIndex: 10,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          animate={{ width: "100%", transition: { duration: 0.3 } }}
          exit={{ width: 0, transition: { delay: 0.4, duration: 0.3 } }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CurtainContainer

import React, { Fragment, useState } from "react";
import MemoBurgerDots from "../../icons/BurgerDots";
import styled from "styled-components";
import CurtainContainer from "./CurtainContainer";
import { Link } from "gatsby";
import theme from "../../../theme/theme.json";
import MemoXIcon from "../../icons/XIcon";
import MemoAdriftCircle from "../../site/AdriftCircle";
import Fade from "./Fade";

const Curtain = () => {
  const [curtainIsVisible, setCurtainIsVisible] = useState(false);
  const [fadeIsVisible, setFadeIsVisible] = useState(false);
  const handleCurtainButtonClick = () => {
    setFadeIsVisible(!fadeIsVisible);
    setCurtainIsVisible(!curtainIsVisible);
  };
  return (
    <Fragment>
      <StyledButton
        aria-label="open mobile navigation"
        type="button"
        onClick={handleCurtainButtonClick}
      >
        <MemoBurgerDots />
      </StyledButton>
      <CurtainContainer isVisible={curtainIsVisible}>
        <CurtainOuter>
          <Fade isVisible={fadeIsVisible}>
            <ExitButton
              aria-label="close mobile navigation"
              type="button"
              onClick={handleCurtainButtonClick}
            >
              <MemoXIcon height={15} width={15} fill={theme.col.primary.low} />
            </ExitButton>
            <CurtainInner id="curtainInner">
              <CurtainContent>
                <CircleContainer>
                  <MemoAdriftCircle
                    width="50px"
                    fill={theme.col.bluegrey[300]}
                  />
                </CircleContainer>
                <nav>
                  <CurtainList>
                    <li>
                      <CurtainListLink to="/">Home</CurtainListLink>
                    </li>
                    <li>
                      <CurtainListLink to="/about">About</CurtainListLink>
                    </li>
                    <li>
                      <CurtainListLink to="/news">News</CurtainListLink>
                    </li>
                    <li>
                      <StyledDetails open>
                        <StyledSummary>Team</StyledSummary>
                        <CurtainListLink to="/core-team">
                          Core Team
                        </CurtainListLink>
                        <CurtainListLink to="/current-students">
                          Current Students
                        </CurtainListLink>
                        <CurtainListLink to="/alumni">Alumni</CurtainListLink>
                      </StyledDetails>
                    </li>
                    <li>
                      <CurtainListLink to="/publications">
                        Research
                      </CurtainListLink>
                    </li>
                    <li>
                      <CurtainListLink to="/join-adrift-lab">
                        Join
                      </CurtainListLink>
                    </li>
                    <li>
                      <CurtainListLink to="/contact">Contact</CurtainListLink>
                    </li>
                  </CurtainList>
                </nav>
              </CurtainContent>
            </CurtainInner>
          </Fade>
        </CurtainOuter>
      </CurtainContainer>
    </Fragment>
  );
};

export default Curtain;

const StyledButton = styled.button`
  font-size: 24px;
  line-height: 1;
`;
const CurtainOuter = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: ${theme.col.bw.w};
  transition: max-width 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurtainInner = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const CurtainList = styled.ul`
  /*text-align: center;
& > * {
    border-top: solid ${theme.col.grey[300]} 2px;
  }
  & > :first-child {
    border-top: none;
  } */
`;

const CurtainContent = styled.div`
  padding: 30px;
  width: 280px;
  border-radius: 20px;
  border: 3px solid ${theme.col.bluegrey[50]};
`;

const CurtainListLink = styled((props) => <Link {...props} />)`
  font-family: ${theme.ff.primary};
  /* font-size: 1.5rem; */
  line-height: 1.2;
  font-weight: 700;
  transition: opacity 0.6s;
  text-decoration: none;
  display: block;
  padding: 8px 0px;
  color: ${theme.col.grey[900]};
  & :first-of-type {
    margin-top: none;
    padding-top: none;
  }
`;

const StyledDetails = styled.details`
  & > a {
    border-left: 2px solid ${theme.col.bluegrey[300]};
    padding-left: 10px;
    margin-left: 4px;
  }
`;

const StyledSummary = styled.summary`
  font-family: ${theme.ff.primary};
  /* font-size: 1.5rem; */
  line-height: 1.2;
  font-weight: 700;
  transition: opacity 0.6s;
  padding: 8px 0px;
  color: ${theme.col.grey[900]};
  & :hover {
    cursor: pointer;
    opacity: 0.6;
  }
  & ::marker {
    color: ${theme.col.bluegrey[300]};
  }
`;

const ExitButton = styled.button`
  position: absolute;
  z-index: 11;
  top: 20px;
  right: 20px;
`;

const CircleContainer = styled.div`
  margin-bottom: 20px;
  & svg {
    margin: 0 0 20px;
    display: block;
    width: 60px;
  }
`;

import React, { Fragment, useState } from "react";
import * as Icon from "react-feather";
import styled from "styled-components";
import { Link } from "gatsby";
import theme from "../../../theme/theme.json";
import DropDownContainer from "./Dropdowncontainer";

const Dropper = ({ label, links }) => {
  const [dropDownIsVisible, setDropDownIsVisible] = useState(false);
  const mapLinks = [
    {
      link: {
        to: "/core-team",
        label: "Core Team",
      },
    },
    {
      link: {
        to: "/current-students",
        label: "Current Students",
      },
    },
    {
      link: {
        to: "/alumni",
        label: "Alumni",
      },
    },
  ];
  const handleDropperButtonClick = () => {
    setDropDownIsVisible(!dropDownIsVisible);
  };
  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        <DropperButton
          aria-label="open dropdown menu"
          type="button"
          onClick={handleDropperButtonClick}
        >
          <DropperLabel>
            {label}
            <Chevron flip={dropDownIsVisible} />
          </DropperLabel>
        </DropperButton>
        <DropDownContainer isVisible={dropDownIsVisible}>
          <DropUl>
            <DropLi to="/core-team">Core Team</DropLi>
            <DropLi to="/current-students">Current Students</DropLi>
            <DropLi to="/alumni">Alumni</DropLi>
          </DropUl>
        </DropDownContainer>
      </div>
    </Fragment>
  );
};

export default Dropper;

const DropperButton = styled.button`
  transition: all 0.6s;
  text-decoration: none;
  display: inline-block;
  color: ${theme.col.bw.w};
  position: relative;
  & :hover {
    color: ${theme.col.primary.light};
  }
`;

const DropperLabel = styled.span`
  display: flex;
  align-items: center;
  font-family: ${theme.ff.primary};
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.75rem;
  transition: color 0.6s;
  text-decoration: none;
  color: white;
  & :hover {
    color: ${theme.col.primary.light};
    opacity: 1;
    cursor: pointer;
  }
`;
const Chevron = styled(Icon.ChevronDown)`
  transition: transform 0.1s;
  transform: ${(props) => (props.flip ? "rotate(180deg)" : "none")};
  position: relative;
  bottom: 1px;
`;

const DropUl = styled.ul`
  width: 100%;
  max-width: 300px;
  z-index: 9999;
`;

const DropLi = styled((props) => <Link {...props} />)`
  font-family: ${theme.ff.primary};
  background-color: ${theme.col.primary.low};
  font-size: 1rem;
  font-weight: bold;
  padding: 0.7rem 0.875rem;
  text-align: center;
  width: 100%;
  max-width: 300px;
  transition: opacity 0.1s ease-in 0s;
  text-decoration: none;
  display: block;
  color: ${theme.col.bw.w};
  & :hover {
    background-color: ${theme.col.primary.light};
    opacity: 1;
  }
`;

import React from "react"
import { motion } from "framer-motion"

const Fade = ({ isVisible, children }) => {
  return (
    <>
      {isVisible && (
        <motion.div
          key="child"
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1, transition: { delay: 0.4 } }}
          exit={{ opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </>
  )
}

export default Fade

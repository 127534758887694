import React from "react"
import styled from "styled-components"

export default ({ t, fs, fw, ff, col, decoration, display, children }) => {
  const tag = t
  return (
    <>
      {(() => {
        if (tag === "h1") {
          return (
            <StyledH1
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH1>
          )
        } else if (tag === "h2") {
          return (
            <StyledH2
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH2>
          )
        } else if (tag === "h3") {
          return (
            <StyledH3
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH3>
          )
        } else if (tag === "h4") {
          return (
            <StyledH4
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH4>
          )
        } else if (tag === "h5") {
          return (
            <StyledH5
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH5>
          )
        } else if (tag === "h6") {
          return (
            <StyledH6
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledH6>
          )
        } else if (tag === "p") {
          return (
            <StyledP
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledP>
          )
        } else {
          return (
            <StyledSpan
              fontSize={fs}
              fontWeight={fw}
              fontFamily={ff}
              color={col}
              decoration={decoration}
              display={display}
            >
              {children}
            </StyledSpan>
          )
        }
      })()}
    </>
  )
}

const StyledSpan = styled.span`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display || "block"};
`
const StyledH1 = styled.h1`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledH2 = styled.h2`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledH3 = styled.h3`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledH4 = styled.h4`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledH5 = styled.h5`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledH6 = styled.h6`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`
const StyledP = styled.p`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  text-decoration: ${props => props.decoration};
  display: ${props => props.display};
`

import * as React from "react";

function InstaIcon({ fill, width, height }) {
  return (
    <svg width={width || 25} height={height || 25} viewBox="0 0 10.971 10.971">
      <path
        d="M5.48 0A5.485 5.485 0 000 5.485a5.485 5.485 0 005.486 5.486 5.485 5.485 0 005.485-5.486A5.485 5.485 0 005.486 0a5.485 5.485 0 00-.006 0zM4.351 2.692h2.3l.398.031.393.13c.589.258.883.804.883 1.638v2.113c0 .662-.179 1.135-.537 1.421-.159.231-.768.346-1.83.346-1.384.038-2.204-.015-2.46-.16-.568-.3-.852-.897-.852-1.793V4.775c0-.345.02-.64.062-.884.276-.8.824-1.199 1.644-1.199zm.093.507c-.523 0-.87.136-1.038.408-.17 0-.254.525-.254 1.576v.698c0 .561.021.982.062 1.26.135.504.598.755 1.39.755.476-.02.927-.03 1.354-.03h.915c.544 0 .87-.39.977-1.169V4.46c0-.841-.4-1.261-1.2-1.261zm2.553.47c.207 0 .334.127.383.382-.09.21-.205.316-.347.316-.14 0-.258-.105-.351-.316 0-.19.105-.317.315-.382zm-1.509.413h.031c.293 0 .576.095.848.285.4.34.6.708.6 1.1v.067c0 .693-.378 1.175-1.133 1.447h-.63c-.779-.265-1.168-.737-1.168-1.416.028-.272.059-.43.093-.475.255-.672.708-1.008 1.36-1.008zm0 .502c-.558 0-.875.327-.95.981.017.176.04.281.067.316.19.42.494.63.914.63.53 0 .846-.325.946-.977 0-.12-.01-.194-.031-.222-.155-.485-.46-.728-.915-.728z"
        fill={fill || "#fff"}
      />
    </svg>
  );
}

const MemoInstaIcon = React.memo(InstaIcon);
export default MemoInstaIcon;

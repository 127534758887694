import React from "react"
import styled from "styled-components"

const Owl = ({ children, margin, mt, mb }) => {
  return (
    <StyledOwl oMt={mt} oMb={mb} oMargin={margin}>
      {children}
    </StyledOwl>
  )
}

export default Owl

const StyledOwl = styled.div`
  margin-top: ${props => props.oMt};
  margin-bottom: ${props => props.oMb};
  & * + * {
    margin-top: ${props => props.oMargin || "1.5rem"};
  }
`

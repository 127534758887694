import React from "react"
import styled from "styled-components"

const Grid = ({ cols, gap, children }) => {
  return (
    <StyledGrid gCols={cols} gGap={gap}>
      {children}
    </StyledGrid>
  )
}

export default Grid

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.gCols}, 1fr);
  grid-gap: ${props => props.gGap || 0};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

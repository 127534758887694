import React from "react";
import Navbar from "./Navbar/Navbar.js";
import Footer from "./Footer.js";
import "../../css/layout.css";
import "../../css/fonts.css";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import theme from "../../../theme/theme.json";

const Navbar = () => {
  return (
    <>
      <StyledHeader>
        <Desktop />
        <Mobile />
      </StyledHeader>
    </>
  );
};

export default Navbar;

const StyledHeader = styled.header`
  height: 10vh;
  background-color: ${theme.col.primary.low};
  min-height: 80px;
  display: flex;
  align-items: center;
`;

const Aligner = styled.div`
  display: flex;
  align-items: center;
`;

const MobileNav = styled.nav`
  padding: 0 5vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  font-size: 24px;
  line-height: 1;
`;

const DesktopNav = styled.nav`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 30px;
  }
`;

const DesktopNavList = styled.ul`
  display: flex;
  align-items: center;
`;

const Dropper = styled.span`
  font-family: var(--font-one);
  font-size: 1rem;
  font-weight: bold;
  cursor: default;
  padding: 0rem 0.75rem;
  transition: all 0.6s;
  text-decoration: none;
  display: inline-block;
  color: white;
  position: relative;
  & :hover > ul {
    visibility: visible;
    max-height: 30vh;
  }
  & :hover > ul * {
    opacity: 1;
    transition-delay: 0.2s;
    transition-duration: 0.15s;
  }
  & :hover {
    color: var(--brightGreen);
  }
`;

const DropUl = styled.ul`
  background-color: var(--lowGreen);
  visibility: hidden;
  max-height: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.4s ease;
  padding-top: 1rem;
  left: 0;
  z-index: 9999;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  font-family: var(--font-one);
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.75rem;
  transition: opacity 0.6s;
  text-decoration: none;
  display: inline-block;
  color: white;
`;

const StyledSoloLink = styled((props) => <Link {...props} />)`
  font-family: var(--font-one);
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.75rem;
  transition: color 0.6s;
  text-decoration: none;
  display: inline-block;
  color: white;
  & :hover {
    color: var(--brightGreen);
    opacity: 1;
  }
`;

const DropLi = styled((props) => <Link {...props} />)`
  font-family: var(--font-one);
  background-color: var(--lowGreen);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.7rem 1.25rem;
  opacity: 0;
  transition: opacity 0.1s ease-in 0s;
  text-decoration: none;
  display: block;
  color: white;
  & :hover {
    background-color: var(--brightGreen);
  }
`;
